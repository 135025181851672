import {http, param} from '../../utils/http'
import conf from '../../config'
import fileDownload from 'js-file-download'
export const API_ROOT = `${conf.API_URL}`

const recallService = {
    findRecall(payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'search', val: payload.search ? payload.search.trim() : '' },
            { query: 'communicationType', val: payload.communicationType },
            { query: 'filterModel', val: payload.filterModel ?  encodeURIComponent(JSON.stringify(payload.filterModel))  : null },
            {query: 'selectedOrgId', val: payload.selectedOrgId},
            { query: 'selectUserType', val: payload.userType },
        ]
        return http.get(`${API_ROOT}/notisphere/communications${param(arr)}`).then(resp => {
            return resp
        })
    },
    getRecallById(id) {
        return http.get(`${API_ROOT}/notisphere/communications/${id}`).then(resp => {
            return resp
        })
    },
    async copyRecallById(id) {
        return http.get(`${API_ROOT}/notisphere/communications/${id}/copy`).then(resp => {
            return resp
        })
    },
    GetRecallHistories (recallId) {
        return http.get(`${API_ROOT}/notisphere/communications/${recallId}/histories`).then(resp => {
            return resp
        })
    },
    getDocumentById(docId) {
        return http.get(`${API_ROOT}/notisphere/communications/document/${docId}`).then(resp => {
            return resp
        })
    },
    deleteRecall (recallId, supplierId) {
        return http.put(`${API_ROOT}/notisphere/communications/${recallId}/delete/supplier/${supplierId}`).then(resp => {
            return resp
        })
    },
    downloadCSVRecall (payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'search', val: payload.search ? payload.search.trim() : '' },
            { query: 'status', val: payload.status },
            { query: 'statusProvider', val: payload.statusProvider },
            { query: 'organizationId', val: payload.supplierOrgId },
            { query: 'category', val: payload.category },
        ]
        return http.get(`${API_ROOT}/notisphere/communications/downloadcsv${param(arr)}`,{ responseType: 'blob' }).then(resp => {
            var date = new Date()
            var DateTime = (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)))  + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) +'-'+ date.getFullYear())
            fileDownload(resp.data, 'Recalls Report ' + DateTime +'.csv','text/csv')
        })
    },
    async downloadEventSummary (payload, type) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'search', val: payload.search ? payload.search.trim() : '' },
            { query: 'status', val: payload.status },
            { query: 'statusProvider', val: payload.statusProvider },
            { query: 'organizationId', val: payload.supplierOrgId },
            { query: 'category', val: payload.category },
        ]
        return http.get(`${API_ROOT}/notisphere/communications/${type}/summary${param(arr)}`,{ responseType: 'blob' }).then(resp => {
            var date = new Date()
            var DateTime = (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)))  + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) +'-'+ date.getFullYear())
            fileDownload(resp.data, 'Event Summary Report ' + DateTime +'.' + type.toLowerCase(), type == 'CSV' ? 'text/csv' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        })
    },
    downloadXLSXRecall (payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'search', val: payload.search ? payload.search.trim() : '' },
            { query: 'status', val: payload.status },
            { query: 'statusProvider', val: payload.statusProvider },
            { query: 'organizationId', val: payload.supplierOrgId },
            { query: 'category', val: payload.category },
        ]
        return http.get(`${API_ROOT}/notisphere/communications/downloadxlsx${param(arr)}`,{ responseType: 'blob' }).then(resp => {
            var date = new Date()
            var DateTime = (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)))  + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) +'-'+ date.getFullYear())
            fileDownload(resp.data, 'Recalls Report ' + DateTime +'.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        })
    },
}

export default recallService
