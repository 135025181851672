import {http, param} from '../../utils/http'
import conf from '../../config'
import fileDownload from 'js-file-download'
export const API_ROOT = `${conf.API_URL}`

const recallService = {
    findRecall(payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'communicationType', val: payload.communicationType },
            { query: 'filterModel', val: payload.filterModel ?  encodeURIComponent(JSON.stringify(payload.filterModel))  : null },
        ]
        return http.get(`${API_ROOT}/provider/communications${param(arr)}`).then(resp => {
            return resp
        })
    },
    getProviderCommunicationId(Id) {
        return http.get(`${API_ROOT}/workflow/${Id}/provider-communication-id`).then(resp => {
            return resp
        })
    },
    getSwitchSites(eventId,communicationType) {
        return http.get(`${API_ROOT}/provider/communications/${eventId}/switch-sites/${communicationType}`).then(resp => {
            return resp
        })
    },
    findMetaSites() {
        return http.get(`${API_ROOT}/provider/communications/meta-sites`).then(resp => {
            return resp
        })
    },
    getSitesStatus() {
        return http.get(`${API_ROOT}/provider/communications/is-site`).then(resp => {
            return resp
        })
    },
    getSearchMeta() {
        return http.get(`${API_ROOT}/provider/communications/search-meta`).then(resp => {
            return resp
        })
    },
    getRecallMetaData () {
        return http.get(`${API_ROOT}/provider/communications/meta`).then(resp => {
            return resp
        })
    },
    async saveCommunicationStep0Info (payload) {
        return http.post(`${API_ROOT}/provider/communications/step0`, payload).then(resp => {
            return resp
        })
    },
    getRecallSummaryById(communicationId,providerCommunicationId) {
        return http.get(`${API_ROOT}/provider/communications/${communicationId}/summary/${providerCommunicationId}`).then(resp => {
            return resp
        })
    },
    getAccessDeniedDetalsById(communicationId,providerCommunicationId) {
        return http.get(`${API_ROOT}/auth/${communicationId}/summary/${providerCommunicationId}`).then(resp => {
            return resp
        })
    },
    getSupplierRecallSummaryById(communicationId) {
        return http.get(`${API_ROOT}/supplier/communications/${communicationId}/summary`).then(resp => {
            return resp
        })
    },
    getRecallresponseDetails(communicationId,providerCommunicationId) {
        return http.get(`${API_ROOT}/provider/communications/${communicationId}/response-details/${providerCommunicationId}`).then(resp => {
            return resp
        })
    },
    updateRecallResponseEmail(communicationId,providerCommunicationId, responseEmail,payload) {
        return http.put(`${API_ROOT}/provider/communications/${communicationId}/update-recall/${responseEmail}/${providerCommunicationId}`,payload).then(resp => {
            return resp
        })
    },
    getRecallById(communicationId,providerCommunicationId) {
        return http.get(`${API_ROOT}/provider/communications/${communicationId}/provider-recall/${providerCommunicationId}`).then(resp => {
            return resp
        })
    },
    getAccessDeniedById(communicationId,providerCommunicationId) {
        return http.get(`${API_ROOT}/auth/${communicationId}/provider-recall/${providerCommunicationId}`).then(resp => {
            return resp
        })
    },
    async updateEvent(communicationId,providerCommunicationId) {
        return http.get(`${API_ROOT}/provider/communications/${communicationId}/update-event`).then(resp => {
            return resp
        })
    },
    getRecallItemDetail(id) {
        return http.get(`${API_ROOT}/provider/communications/provider-edit/${id}`).then(resp => {
            return resp
        })
    },
    getDocumentById(docId) {
        return http.get(`${API_ROOT}/provider/communications/document/${docId}`).then(resp => {
            return resp
        })
    },
    deleteDocumentById(docId) {
        return http.put(`${API_ROOT}/provider/communications/document/${docId}`).then(resp => {
            return resp
        })
    },
    getRecallActions(communicationId,providerCommunicationId) {
        return http.get(`${API_ROOT}/provider/communications/${communicationId}/actions/${providerCommunicationId}`).then(resp => {
            return resp
        })
    },
    updateRecallActions(communicationId,communicationType, payload,providerCommunicationId) {
        return http.put(`${API_ROOT}/provider/communications/${communicationId}/${communicationType}/actions/${providerCommunicationId}`, payload).then(resp => {
            return resp
        })
    },
    getRecallActionFilesAsync (communicationId, actionId, actionType,providerCommunicationId, email) {
        return http.get(`${API_ROOT}/provider/communications/${communicationId}/actions/${actionId}/file/${providerCommunicationId}/?actionType=${actionType}&email=${email}`).then(resp => {
            return resp
        })
    },
    uploadRecallActionFile (formData, communicationId, communicationType, actionId,providerCommunicationId, email) {
        return http.post(`${API_ROOT}/provider/communications/${communicationId}/${communicationType}/actions/${actionId}/file/${providerCommunicationId}/?email=${email}`, formData).then(resp => {
            return resp
        })
    },
    uploadRecallCommunicationActionFile (formData, communicationId) {
        return http.post(`${API_ROOT}/provider/communications/${communicationId}/file`, formData).then(resp => {
            return resp
        })
    },
    deleteRecallActionFileAsync (communicationId,communicationType, actionId, fileId) {
        return http.delete(`${API_ROOT}/provider/communications/${communicationId}/${communicationType}/actions/${actionId}/file/${fileId}`).then(resp => {
            return resp
        })
    },
    getFileById (fileId) {
        return http.get(`${API_ROOT}/provider/communications/file/${fileId}`).then(resp => {
            return resp
        })
    },
    //delegateAction
    getDelegateFileById (fileId) {
        return http.get(`${API_ROOT}/provider/communications/file/delegateAction/${fileId}`).then(resp => {
            return resp
        })
    },
    acknowledgeRecall (communicationId,providerCommunicationId) {
        return http.put(`${API_ROOT}/provider/communications/${communicationId}/acknowledge/${providerCommunicationId}`).then(resp => {
            return resp
        })
    },
    changeRecallCompleteStatus (communicationId,providerCommunicationId) {
        return http.put(`${API_ROOT}/provider/communications/${communicationId}/change-status/${providerCommunicationId}`).then(resp => {
            return resp
        })
    },

    //Conversation
    getRecallConversations (communicationId,providerCommunicationId) {
        return http.get(`${API_ROOT}/provider/communications/${communicationId}/${providerCommunicationId}/conversations`).then(resp => {
            return resp
        })
    },
    createRecallConversation (communicationId, communicationType, providerCommunicationId, supplierId, payload) {
        return http.post(`${API_ROOT}/provider/communications/${communicationId}/${communicationType}/conversations/${providerCommunicationId}/${supplierId}`, payload).then(resp => {
            return resp
        })
    },
    updateRecallConversation (communicationId, communicationType, payload) {
        return http.put(`${API_ROOT}/provider/communications/${communicationId}/${communicationType}/conversations/${payload.id}`, payload).then(resp => {
            return resp
        })
    },

    //Conversation - Message
    getRecallConversation (communicationId, conversationId,providerCommunicationId) {
        return http.get(`${API_ROOT}/provider/communications/${communicationId}/conversations/${conversationId}/${providerCommunicationId}`).then(resp => {
            return resp
        })
    },
    createRecallConversationMessage (communicationId, communicationType, conversationId, formData,providerCommunicationId) {
        return http.post(`${API_ROOT}/provider/communications/${communicationId}/${communicationType}/conversations/${conversationId}/message/${providerCommunicationId}`, formData).then(resp => {
            return resp
        })
    },
    getRecallConversationMessageFile (communicationId, conversationId, fileId) {
        return http.get(`${API_ROOT}/provider/communications/${communicationId}/conversations/${conversationId}/file/${fileId}`).then(resp => {
            return resp
        })
    },

    getAttachementByEmail (communicationId, OrganizationId,emailname,delegateId) {
        return http.get(`${API_ROOT}/provider/communications/providerMail/${communicationId}/infomail/${OrganizationId}/${emailname}/${delegateId}`).then(resp => {
            return resp
        })
    },
    //ReportHistory
    AddReportDownloadHistory (communicationId,providerCommunicationId,selectedReport) {
        return http.post(`${API_ROOT}/provider/communications/${communicationId}/add-report-history/${providerCommunicationId}/${selectedReport}`).then(resp => {
            return resp
        })
    },
    GetReportHistories (communicationId,providerCommunicationId,selectedReport) {
        return http.get(`${API_ROOT}/provider/communications/${communicationId}/report-history/${providerCommunicationId}/${selectedReport}`).then(resp => {
            return resp
        })
    },
    //Histories
    GetRecallHistories (communicationId,providerCommunicationId) {
        return http.get(`${API_ROOT}/provider/communications/${communicationId}/histories/${providerCommunicationId}`).then(resp => {
            return resp
        })
    },
    UpdateRecallHistories (communicationId,providerCommunicationId) {
        return http.put(`${API_ROOT}/provider/communications/${communicationId}/update-notification/${providerCommunicationId}`).then(resp => {
            return resp
        })
    },

    //Provider report
    downloadproviderAffectedLocationReport(id,coveredStatus,userId,extensionType,communicationUID,providerCommunicationId,siteName) {
        if(extensionType=='xlxs')
        {return http.get(`${API_ROOT}/provider/communications/${id}/provideraffectedlocation/${coveredStatus}/${userId}/${extensionType}/${providerCommunicationId}`,{ responseType: 'blob' }).then(resp => {
            if(siteName != '' && siteName != null)
                fileDownload(resp.data, 'Affected Locations Report_'+communicationUID +'_' + siteName + '.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
            else
                fileDownload(resp.data, 'Affected Locations Report_'+communicationUID+'.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        })}
        else{
            return http.get(`${API_ROOT}/provider/communications/${id}/provideraffectedlocation/${coveredStatus}/${userId}/${extensionType}/${providerCommunicationId}`,{ responseType: 'blob' }).then(resp => {
                if(siteName != '' && siteName != null)
                    fileDownload(resp.data, 'Affected Locations Report_'+communicationUID +'_' + siteName + '.csv','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
                else
                    fileDownload(resp.data, 'Affected Locations Report_'+communicationUID+'.csv','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
            })
        }

    },
    downloadproviderAffectedProductsReport(id,extensionType,communicationUID,providerCommunicationId,siteName) {
        if(extensionType=='xlxs')
        {return http.get(`${API_ROOT}/provider/communications/${id}/provideraffectedproducts/${extensionType}/provider-recall/${providerCommunicationId}`,{ responseType: 'blob' }).then(resp => {
            if(siteName != '' && siteName != null)
                fileDownload(resp.data, 'Affected Products Report_'+communicationUID +'_' + siteName + '.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
            else
                fileDownload(resp.data, 'Affected Products Report_'+communicationUID +'.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        })}
        else{
            return http.get(`${API_ROOT}/provider/communications/${id}/provideraffectedproducts/${extensionType}/provider-recall/${providerCommunicationId}`,{ responseType: 'blob' }).then(resp => {
                if(siteName != '' && siteName != null)
                    fileDownload(resp.data, 'Affected Products Report_'+communicationUID+'_' + siteName + '.csv','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
                else
                    fileDownload(resp.data, 'Affected Products Report_'+communicationUID+'.csv','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
            })
        }

    },
    downloadCCReport(id,extensionType,communicationUID,providerCommunicationId,siteName) {
        if(extensionType=='xlxs')
        {return http.get(`${API_ROOT}/provider/communications/${id}/customizedcontent/${extensionType}/provider-recall/${providerCommunicationId}`,{ responseType: 'blob' }).then(resp => {
            fileDownload(resp.data, 'Customized Content Report_'+communicationUID + '_' + siteName + '.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        })}
        else{
            return http.get(`${API_ROOT}/provider/communications/${id}/customizedcontent/${extensionType}/provider-recall/${providerCommunicationId}`,{ responseType: 'blob' }).then(resp => {
                fileDownload(resp.data, 'Customized Content Report_'+communicationUID + '_' + siteName + '.csv','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
            })
        }

    },
    getDamain(id, communicationId) {
        return http.get(`${API_ROOT}/provider/communications/valid-domain/emailid/${id}/${communicationId}`).then(resp => {
            return resp
        })
    },
    async getSupplierDamain(id, communicationId) {
        return http.get(`${API_ROOT}/supplier/communications/valid-domain/emailid/${id}/${communicationId}`).then(resp => {
            return resp
        })
    },
    async downloadChangeHistoryXLSXReport(communicationId, providerCommunicationId, communicationUID) {
        return http.get(`${API_ROOT}/provider/communications/${communicationId}/actions/provider/change-history-report/${providerCommunicationId}`,{ responseType: 'blob' }).then(resp => {
            fileDownload(resp.data, 'Change History Report '+communicationUID+'.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        })
    },
    //Delegate Services
    submitDelegate(communicationId,delegateId,payload,providerCommunicationId) {
        return http.post(`${API_ROOT}/provider/communications/${communicationId}/${delegateId}/${providerCommunicationId}`, payload).then(resp => {
            return resp
        })
    },
    async submitSupplierDelegate(communicationId,delegateId, payload) {
        return http.post(`${API_ROOT}/supplier/communications/${communicationId}/${delegateId}`, payload).then(resp => {
            return resp
        })
    },
    //New Delegate Services
    submitNewDelegate(communicationId,delegateId,payload,payload1) {
        return http.post(`${API_ROOT}/provider/communications/${communicationId}/${delegateId}/${payload1}`, payload).then(resp => {
            return resp
        })
    },
    //New Delegate Services
    ResendDelegateMail(communicationId,delegateId,providerCommunicationId) {
        return http.post(`${API_ROOT}/provider/communications/${communicationId}/resendmail/${delegateId}/${providerCommunicationId}`).then(resp => {
            return resp
        })
    },
    async ResendDelegateMailSupplier(communicationId,delegateId) {
        return http.post(`${API_ROOT}/supplier/communications/${communicationId}/resendmail/${delegateId}`).then(resp => {
            return resp
        })
    },
    //Provider info mail
    providerInfoMail(communicationId,delegateId,payload,providerCommunicationId,communicationType) {
        return http.post(`${API_ROOT}/workflow/providerMail/${communicationId}/${delegateId}/${providerCommunicationId}/${communicationType}`, payload).then(resp => {
            return resp
        })
    },
    providerResponseMail(payload) {
        return http.put(`${API_ROOT}/provider/communications/response`, payload).then(resp => {
            return resp
        })
    },
    getProviderMail (communicationId,providerOrgId,providerCommunicationId) {
        return http.get(`${API_ROOT}/provider/communications/providerMail/${communicationId}/${providerOrgId}/${providerCommunicationId}`).then(resp => {
            return resp
        })
    },
    getEmail(type) {
        return http.get(`${API_ROOT}/provider/communications/providerInfoMail/${type}`).then(resp => {
            return resp
        })
    },
    //Delegate listing
    GetRecallAction_DelegateListing (communicationId,providerCommunicationId) {
        return http.get(`${API_ROOT}/provider/communications/${communicationId}/delegate/${providerCommunicationId}`).then(resp => {
            return resp
        })
    },

    async GetSupplierRecallAction_DelegateListing (communicationId,providerCommunicationId) {
        return http.get(`${API_ROOT}/supplier/communications/${communicationId}/delegate`).then(resp => {
            return resp
        })
    },
    GetAction_DelegateEmailList (organizationId,isincludedl= false) {
        return http.get(`${API_ROOT}/delegate/${organizationId}/${isincludedl}/emaillist`).then(resp => {
            return resp
        })
    },
    //async GetRecallResponse_Email(organizationId) {
    async GetEmailList(organizationId) {
        return http.get(`${API_ROOT}/meta/${organizationId}/emaillist`).then(resp => {
            return resp
        })
    },
    GetRecallAction_ProviderEmailList (organizationId,communicationId) {
        return http.get(`${API_ROOT}/provider/communications/${organizationId}/${communicationId}/provideremaillist`).then(resp => {
            return resp
        })
    },

    GetRecallAction_DelegateListing_Detail (communicationId, delegateId) {
        return http.get(`${API_ROOT}/provider/communications/${communicationId}/${delegateId}`).then(resp => {
            return resp
        })
    },
    async GetSupplierRecallAction_DelegateListing_Detail (communicationId, delegateId) {
        return http.get(`${API_ROOT}/supplier/communications/${communicationId}/${delegateId}`).then(resp => {
            return resp
        })
    },
    GetSupplierNameList () {
        return http.get(`${API_ROOT}/provider/communications/supplierNameList`).then(resp => {
            return resp
        })
    },

    //Recall View - Actions
    GetRecallAction (communicationId,providerCommunicationId) {
        return http.get(`${API_ROOT}/provider/communications/${communicationId}/delegate/actions/${providerCommunicationId}`).then(resp => {
            return resp
        })
    },
    async GetSupplierRecallAction_Detail (communicationId,delegateId,actionId) {
        return http.get(`${API_ROOT}/supplier/communications/${communicationId}/${delegateId}/actions/${actionId}`).then(resp => {
            return resp
        })
    },
    async GetSupplierRecallAction(communicationId) {
        return http.get(`${API_ROOT}/supplier/communications/${communicationId}/delegate/actions`).then(resp => {
            return resp
        })
    },
    //Provider Internal Actions
    saveProviderInternalActions (payload) {
        return http.post(`${API_ROOT}/provider/communications/internalaction`, payload).then(resp => {
            return resp
        })
    },

    getProviderActionDetail (id,providerOrgId,providerCommunicationId) {
        return http.get(`${API_ROOT}/provider/communications/${id}/${providerOrgId}/internalaction/${providerCommunicationId}`).then(resp => {
            return resp
        })
    },
    async getSupplierActionDetail (id,supplierOrgId) {
        return http.get(`${API_ROOT}/supplier/communications/${id}/${supplierOrgId}/internalaction`).then(resp => {
            return resp
        })
    },
    async getSupportigDoc (id) {
        return http.get(`${API_ROOT}/provider/communications/${id}/supportingdoc`).then(resp => {
            return resp
        })
    },
    async getSupplierSupportigDoc (id) {
        return http.get(`${API_ROOT}/supplier/communications/${id}/supportingdoc`).then(resp => {
            return resp
        })
    },
    saveRecallChangeHistory(payload) {
        return http.post(`${API_ROOT}/provider/communications/change-history`, payload).then(resp => {
            return resp
        })
    },
    findProviderSummaryReport(payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'status', val: payload.status },
        ]
        return http.get(`${API_ROOT}/provider/communications/providerRecallSummaryReport/${param(arr)}`,{ responseType: 'blob' }).then(resp => {
            fileDownload(resp.data,'Provider’s Recalls Summary Report.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        })
    },

    findProviderSummaryCSVReport(payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'status', val: payload.status },
        ]
        return http.get(`${API_ROOT}/provider/communications/providerRecallSummaryReport/csv/${param(arr)}`,{ responseType: 'blob' }).then(resp => {
            fileDownload(resp.data, 'Provider Recall Summary Report.csv','text/csv')
        })
    },
    findDelegateSummaryReport(payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'status', val: payload.status },
        ]
        return http.get(`${API_ROOT}/provider/communications/providerAllDelegateSummaryReport/${param(arr)}`,{ responseType: 'blob' }).then(resp => {
            fileDownload(resp.data,'Delegate Summary Report.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        })
    },

    findDelgateSummaryCSVReport(payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'status', val: payload.status },
        ]
        return http.get(`${API_ROOT}/provider/communications/providerAllDelegateSummaryReport/csv/${param(arr)}`,{ responseType: 'blob' }).then(resp => {
            fileDownload(resp.data, 'Delegate Summary Report.csv','text/csv')
        })
    },
    findSupplierContacts(orgId) {
        return http.get(`${API_ROOT}/provider/communications/suppliercontact/${orgId}`).then(resp => {
            return resp
        })
    },
    async sendNotification (communicationId, communicationType, providerCommunicationId) {
        return http.put(`${API_ROOT}/provider/communications/${communicationId}/send-notification/${communicationType}/${providerCommunicationId}`).then(resp => {
            return resp
        })
    },
    getCommunicationSummaryById(communicationId) {
        return http.get(`${API_ROOT}/supplier/communications/${communicationId}/summary`).then(resp => {
            return resp
        })
    },
    // getAccessDeniedDetalsById(communicationId) {
    //     return http.get(`${API_ROOT}/supplier/communications/${communicationId}/summary`).then(resp => {
    //         return resp
    //     })
    // },
}

export default recallService
