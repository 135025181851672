import { AgGridVue } from 'ag-grid-vue'
export default {
    components: {
        AgGridVue
    },
    props: {
    //name for the table
        tableName: { type: String, default: '' },
        addMoreLabel: { type: String, default: 'Add' },
        columnDefs: { type: Array, default: [], required: true },
        gridOptions: { type: Object, default: () => { }, required: false },
        items: { type: Array, default: [], required: true },
        total: { type: Number, default: 0, required: true },
        rowModelType: { type: String, default: 'serverSide', required: true },
        showRecallReportTitle: { type: String, default: 'Recalls Report' },
        showAddMore: { type: Boolean, default: false },
        showRecallReport: { type: Boolean, default: false },
        showEventSummaryReport: { type: Boolean, default: false },
        showSupplierSalesRepReport: { type: Boolean, default: false },
        showCombineReport: { type: Boolean, default: false },
        showProviderOrgReport: { type: Boolean, default: false },
        showProviderOrgUsageReport: { type: Boolean, default: false },
        showV: { type: Boolean, default: false },
        showE: { type: Boolean, default: false },
        showI: { type: Boolean, default: false },
        showNC: { type: Boolean, default: false },
        defaultColDef: { type: Object, default: () => ({}), required: false },
        lastUpdated: { type: String, default: '', required: false },
        showGlobalSearch: { type: Boolean, default: false, required: false },
        hideGlobalSearchError: { type: Boolean, default: false, required: false },
        accountFilterOptions: { type: Array, default: () => [], required: false },
        showFilter: { type: Boolean, default: false },
    },
    data() {
        return {
            overlayNoRowsTemplate: null,
            _gridOptions: this.gridOptions,
            pagination: false,
            // cacheBlockSize: 10,
            // paginationPageSize: 50,
            rowHeight: 50,
            perPage: 50,
            perPageEmit: 50,
            currentPage: 1,
            per_page: [50, 100, 250, 500],
            queryParams: {
                per_page: 50,
                page: 1,
            },
            gridApi: null,
            columnApi: null,
            columnTypes: null
        }
    },
    created() {
        this.columnTypes = {
            rightColumn: {
                cellClass: 'ag-right-aligned-cell'
            },
            centerColumn: {
                cellClass: 'ag-center-aligned-cell'
            }
        }
        this._gridOptions = {
            ...this.gridOptions,
            ...{
                suppressDragLeaveHidesColumns: true,
                enableCellChangeFlash: false,
                suppressRowTransform: true,
                suppressRowClickSelection: true,
                rowModelType: 'serverSide',
                cacheBlockSize: 500,
                maxConcurrentDatasourceRequests: 2,
                maxBlocksInCache: 10,
                headerHeight: 40,
                rowHeight: 32,
                enableRangeSelection: true,
                sortingOrder: ['asc', 'desc', null],
                enableBrowserTooltips: true,
                defaultColDef: {
                    ...{
                        editable: false,
                        sortable: true,
                        suppressVerticalScroll: true,
                        resizable: true,
                        flex: 1,
                        wrapText: true,
                        autoHeight: true,
                        wrapHeaderText: true,
                        autoHeaderHeight: true,
                        filterParams: {
                            suppressAndOrCondition: true,
                            values: [],
                            filterOptions: ['contains'],
                            buttons: ['apply', 'reset'],
                            closeOnApply: true,
                        },
                    },
                    ...this.defaultColDef
                },
                onColumnVisible: this.onColumnVisible,
                onColumnMoved: this.onColumnMoved,
                onColumnResized: this.onColumnResized,
                onColumnPinned: this.onColumnPinned
            }
        }
        this.overlayNoRowsTemplate =
      '<label style="padding: 10px; font-size:16px; text-align: left; width:100%;height:100%;">No record found</label>'
    },
    watch: {
        perPageEmit(val) {
            this.$emit('update:searchQuery', {
                pageNumber: this.currentPage,
                pageSize: val,
            })
            this.onGridReady({ api: this.gridApi, columnApi: this.columnApi })
        },
        currentPage(val) {
            this.$emit('update:searchQuery', {
                pageNumber: val,
                pageSize: this.perPageEmit,
                sort: this.queryParams.sort,
            })
            this.onGridReady({ api: this.gridApi, columnApi: this.columnApi })
        },
        queryParams: {
            deep: true,
            handler(val) {
                this.$emit('update:searchQuery', {
                    pageNumber: this.currentPage,
                    pageSize: this.perPageEmit,
                    sort: val.sort,
                })
            }
        },

    },
    methods: {
        selectPerPage(perPage) {
            this.perPage = perPage
            this.perPageEmit = perPage
        },
        onColumnMoved(params) {
            this.$emit('saveState', params)
        },
        onColumnResized(params) {
            this.$emit('saveState', params)
        },
        onColumnPinned(params) {
            this.$emit('saveState', params)
        },
        onColumnVisible(params) {
            this.$emit('saveState', params)
        },
        onGridReady(params) {
            this.gridApi = params.api
            this.columnApi = params.columnApi
            this.$emit('fetchData', params )
        },
        onAccountFilterChange(val) {
            var params = { api: this.gridApi, columnApi: this.columnApi, organizationId: val }
            this.$emit('onAccountFilterChange', params)
        },
        cellClicked(event) {
            this.$emit('onCellClicked', event)
        },
        addDClick() {
            this.$emit('addDClick')
        },
        addProviderClick() {
            this.$emit('addProviderClick')
        },
        addDelegateReportClick() {
            this.$emit('addDelegateReportClick')
        },
        addDelegateCSVReportClick() {
            this.$emit('addDelegateCSVReportClick')
        },
        btnRecallCSVClick() {
            this.$emit('btnRecallCSVClick')
        },
        addMoreClick() {
            this.$emit('addMoreClick')
        },
        btnRecallXLSXClick() {
            this.$emit('btnRecallXLSXClick')
        },
        btnEventSummaryCSVClick() {
            this.$emit('btnEventSummaryCSVClick')
        },
        btnEventSummaryXLSXClick() {
            this.$emit('btnEventSummaryXLSXClick')
        },
        downloadsuppliersalesrepReport() {
            this.$emit('downloadsuppliersalesrepReport')
        },
        btnProviderAccountCSVClick() {
            this.$emit('btnProviderAccountCSVClick')
        },
        btnProviderAccountXLSXClick() {
            this.$emit('btnProviderAccountXLSXClick')
        },
        btnProviderAccountUsageXLSXClick(){
            this.$emit('btnProviderAccountUsageXLSXClick')
        },
        addVerifyClick() {
            this.$emit('addVerifyClick')
        },
        addEmailedClick() {
            this.$emit('addEmailedClick')
        },
        addInvalidClick() {
            this.$emit('addInvalidClick')
        },
        addCoveredClick() {
            this.$emit('addCoveredClick')
        },
        DisableClick() {
            this.$emit('DisableClick')
        },
        onGlobalSearchChange(val) {
            this.$emit('onGlobalSearchChange', val)
        },
        onTypeFilterChange(val) {
            var params = { api: this.gridApi, columnApi: this.columnApi, type: val }
            this.$emit('onTypeFilterChange', params)
        },
    }
}