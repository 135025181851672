import _ from 'lodash'
import { faLeaf } from '@fortawesome/free-solid-svg-icons'

export default {
    props: {
        isUserProfile: { type: Boolean, default: false },
        title: { type: String, default: 'Table Name' },
        btnLabel: { type: String, default: 'Add' },
        btnproviderLabel: { type: String, default: 'Providers' },
        btnVerifyLabel: { type: String, default: 'Covered' },
        btnEmailLabel: { type: String, default: 'Emailed' },
        btnInvalidLabel: { type: String, default: 'Invalid' },
        btnCoveredLabel: { type: String, default: 'Not Covered' },
        btnIcon: { type: String, default: 'fas fa-plus' },
        showBtn: { type: Boolean, default: false },
        showDBtn: { type: Boolean, default: false },
        showProviderBtn: { type: Boolean, default: false },
        showCombineReportBtn: { type: Boolean, default: false },
        showErReportBtn: { type: Boolean, default: false },
        showRecallReportBtn: { type: Boolean, default: false },
        showEventSummaryReportBtn: { type: Boolean, default: false },
        showSupplierSalesRepBtn: { type: Boolean, default: false },
        showRecallReportTitle: { type: String, default: 'Recalls Report' },
        showProviderOrgReportBtn: { type: Boolean, default: false },
        showProviderOrgUsageReportBtn: { type: Boolean, default: false },
        showSupplierBtn: { type: Boolean, default: false },
        showSupplierLocationBtn: { type: Boolean, default: false },
        showCBtn: { type: Boolean, default: false },
        showVBtn: { type: Boolean, default: false },
        showEBtn: { type: Boolean, default: false },
        showIBtn: { type: Boolean, default: false },
        terms: { type: Boolean, default: false },
        showKnownproviderbtn: { type: Boolean, default: false },
        showUserAccountDownloadReportBtn: { type: Boolean, default: false },
        showGlobalSearch: { type: Boolean, default: false, required: false },
        hideGlobalSearchError: { type: Boolean, default: false, required: false },
        accountFilterOptions: { type: Array, default: () => [], required: false },
        showFilter: { type: Boolean, default: false }
    },
    data() {
        return {
            disabled: false,
            globalSearchText: '',
            TYPE_OPTIONS: [
                { value: 'PROVIDER', text: 'Provider' },
                { value: 'SUPPLIER', text: 'Supplier' },
            ],
            typeFilterVal: 'PROVIDER',
            accountFilterVal: -1
        }
    },
    created() {
        localStorage.setItem('typeFilterVal', this.typeFilterVal)
    },
    watch: {
        globalSearchText(val) {
            this.$emit('onGlobalSearchChange', val)
        },
        typeFilterVal(val) {
            this.accountFilterVal = -1
            this.$emit('onTypeFilterChange', val)
            localStorage.setItem('typeFilterVal', val)
        },
        accountFilterVal(val) {
            this.$emit('onAccountFilterChange', val)
        }
    },
    computed: {
        isDisabled: function () {
            return !this.disabled
        },
        filteredTypeOptions() {
            if (
                this.$store.state.userType === 'NOTISPHERE' &&
              this._checkPermissions(['Notisphere_ProviderReadOnly'])
            ) {
                return this.TYPE_OPTIONS.filter(option => option.value === 'PROVIDER')
            }
            return this.TYPE_OPTIONS
        }
    },
    methods: {
        isDisabledbtn: function () {
            if (this.disabled) {
                this.disabled = false
            } else {
                this.disabled = true
            }
            return this.disabled ? !this.terms : this.terms
        },
        isDisabled1: function () {
            if (this.terms) {
                this.terms = false
            } else {
                this.terms = true
            }
            return this.terms
        },
        btnClick() {
            this.$emit('btnClick')
        },
        btnknownProClick() {
            this.$emit('btnknownProClick')
        },
        btnDClick() {
            this.$emit('btnDClick')
        },
        btnProviderClick() {
            this.$emit('btnProviderClick')
        },
        exportsupplierlocationReport() {
            this.$emit('exportsupplierlocationReport')
        },
        downloadsupplierlocationReport() {
            this.$emit('downloadsupplierlocationReport')
        },
        btnaddDelegateReportClick() {
            this.$emit('btnaddDelegateReportClick')
        },
        btnaddDelegateCSVReportClick() {
            this.$emit('btnaddDelegateCSVReportClick')
        },
        btnErCSVClick() {
            this.$emit('btnErCSVClick')
        },
        btnErXLSXClick() {
            this.$emit('btnErXLSXClick')
        },
        addDClick() {
            this.$emit('addDClick')
        },
        addProviderClick() {
            this.$emit('addProviderClick')
        },
        addDelegateReportClick() {
            this.$emit('addDelegateReportClick')
        },
        addDelegateCSVReportClick() {
            this.$emit('addDelegateCSVReportClick')
        },
        btnRecallCSVClick() {
            this.$emit('btnRecallCSVClick')
        },
        btnRecallXLSXClick() {
            this.$emit('btnRecallXLSXClick')
        },
        btnEventSummaryCSVClick() {
            this.$emit('btnEventSummaryCSVClick')
        },
        btnEventSummaryXLSXClick() {
            this.$emit('btnEventSummaryXLSXClick')
        },
        downloadsuppliersalesrepReport() {
            this.$emit('downloadsuppliersalesrepReport')
        },
        btnProviderAccountCSVClick() {
            this.$emit('btnProviderAccountCSVClick')
        },
        btnProviderAccountXLSXClick() {
            this.$emit('btnProviderAccountXLSXClick')
        },
        btnProviderAccountUsageXLSXClick() {
            this.$emit('btnProviderAccountUsageXLSXClick')
        },
        btnVerify() {
            this.$emit('btnVerify')
        },
        btnEmailed() {
            this.$emit('btnEmailed')
        },
        btnInvalid() {
            this.$emit('btnInvalid')
        },
        btnCovered() {
            this.$emit('btnCovered')
        },
        btnDisbled() {
            return this.isDisabledbtn()
        },
        btnUserAccountReportXLSXClick() {
            this.$emit('btnUserAccountReportXLSXClick')
        },
    },
}
