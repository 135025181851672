import Vue from 'vue'
import axios from 'axios'
import _ from 'lodash'
import { getAccessToken, getRefreshToken } from '../misc/auth'
import store from '../store'
import { AUTH_ACTIONS } from '../store/actions'
import { AUTH_MUTATIONS } from '../store/mutations'
import conf from '../config'
import noti from '../utils/noti_toast'
import appStore from '../store'
import { APP_MUTATIONS } from '../store/mutations'
import moment from 'moment-timezone'
Vue.prototype.$http = axios

let fetchingTokenRequest
var numberOfAjaxCallPending = 0

const fetchAccessToken = () => {
    if (!fetchingTokenRequest) {
        let refresh_token = getRefreshToken()
        if (refresh_token) {
            fetchingTokenRequest = store.dispatch(AUTH_ACTIONS.LOGIN, { client_id: conf.CLIENT_ID, grant_type: 'refresh_token', refresh_token: refresh_token })
        } else {
            store.commit(AUTH_MUTATIONS.AUTH_ERROR)
            throw new axios.Cancel('Operation canceled.')
        }
    }
    return fetchingTokenRequest
}

const setHeaderAuthorization = (reqConfig) => {
    let access_token = getAccessToken()
    if (access_token) {
        reqConfig.headers['Authorization'] = `Bearer ${access_token}`
        reqConfig.headers['LocalTimeZone'] = moment.tz.guess()
        reqConfig.headers['Cache-Control']='no-cache'
        reqConfig.headers['Pragma']='no-cache'
    }
}

const isAuthRequest = (config) => {
    return config.url === `${conf.API_URL}/auth/token`
}

const isMeRequest = (config) => {
    return config.method === 'get' && config.url === `${conf.API_URL}/me`
}

//interceptors handler
axios.interceptors.request.use(
    async (reqConfig) => {
        numberOfAjaxCallPending ++
        appStore.commit(APP_MUTATIONS.LOADING_START)

        let access_token = getAccessToken()
        const refresh_token = getRefreshToken()
        if (!access_token) {
            if (!isAuthRequest(reqConfig) && refresh_token) {
                await fetchAccessToken().then(resp => {
                    fetchingTokenRequest = null
                    if (resp && !resp.error) {
                        setHeaderAuthorization(reqConfig)
                    } else {
                        store.commit(AUTH_MUTATIONS.AUTH_ERROR)
                        throw new axios.Cancel('Unauthorized.')
                    }
                }).catch(_error => {
                    fetchingTokenRequest = null
                    store.commit(AUTH_MUTATIONS.AUTH_ERROR)
                    return Promise.reject(_error)
                })
            }
            if(isMeRequest(reqConfig) && !refresh_token) {
                return new axios.Cancel()
            }
        } else {
            setHeaderAuthorization(reqConfig)
        }
        return reqConfig
    },
    error => Promise.reject(error)
)

axios.interceptors.response.use(
    resp => {
        numberOfAjaxCallPending--
        if (numberOfAjaxCallPending == 0) {
            appStore.commit(APP_MUTATIONS.LOADING_END)
        }
        let appVersion=process.env.VUE_APP_VERSION
        let apiversion = resp?.headers['app-version'] || 'default'
        if(appVersion === apiversion && apiversion !== localStorage.getItem('app-version'))
        {
            localStorage.setItem('app-version', apiversion)
        }
        else
        {
            let array =['/provider/recall-notice-webform','/provider/disruption-notice-webform','/supplier-delegate-webform',
                '/delegate-webform','/reset-password','/verify-email','/registration','/success-signup','/forgot-password','/success', '/success-signup',
                '/not-found','/forbidden', '/provider/fda', '/notisphere/fda-devices', '/notisphere/fda-drugs']
            if(!array.some(substring=>window.location?.pathname?.includes(substring))){
                if (apiversion !== localStorage.getItem('app-version') && resp.config.method == 'get') {
                    appStore.commit(APP_MUTATIONS.APP_NEW_VERSION_AVAILABLE, true)
                    appStore.commit(APP_MUTATIONS.APP_NEW_VERSION, apiversion)
                }
            }
        }
        if(!resp.data.ok){
            noti.error('Error', resp.data.m)
        }
        return resp
    },
    error => {
        numberOfAjaxCallPending--
        if (numberOfAjaxCallPending == 0) {
            appStore.commit(APP_MUTATIONS.LOADING_END)
        }

        if (_.isObject(error.response)) {
            if (_.isObject(error.response.data) && error.response.data.m && error.response.data.ok === false) {
                noti.error('Error', error.response.data.m)
            } else {
                noti.error('Error', error.message)
            }

            const { config, response: { status } } = error

            if (status === 401) {
                return fetchAccessToken().then(resp => {
                    fetchingTokenRequest = null
                    if (resp && !resp.error) {
                        setHeaderAuthorization(config)
                        return axios(config)
                    }
                    store.commit(AUTH_MUTATIONS.AUTH_ERROR)
                    throw new axios.Cancel('Unauthorized.')
                }).catch(_error => {
                    fetchingTokenRequest = null
                    store.commit(AUTH_MUTATIONS.AUTH_ERROR)
                    return Promise.reject(_error)
                })
            }
        }
        return Promise.reject(error)
    }
)